<template>
  <button
    class="btn botao-voltar"
    @click="aoClicar ? aoClicar() : voltarTela()"
  >
    <IconeVoltar class="icone" />
  </button>
</template>

<script>
import { useRouter } from "vue-router";
import IconeVoltar from "@/components/shared/imagens/IconeVoltar.vue";

export default {
  name: "BotaoVoltar",
  components: {
    IconeVoltar,
  },
  props: {
    aoClicar: {
      type: Function,
      required: false,
    },
  },
  setup() {
    const router = useRouter();
    function voltarTela() {
      router.back();
    }
    return { voltarTela };
  },
};
</script>

<style scoped>
.botao-voltar {
  padding: 0px;
}
.botao-voltar .icone {
  --cor-tema: #fff;
  width: 32px;
  height: 32px;
}
</style>