<template>
  <div>
    <div class="cabecalho d-flex flex-row align-items-center">
      <BotaoVoltar :aoClicar="() => $router.replace({ name: 'loja' })" />
      <h1 class="my-0 mx-2" v-if="mercadoria">{{ mercadoria.nome }}</h1>
    </div>

    <div class="detalhe-mercadoria" v-if="mercadoria">
      <div class="text-center m-5">
        <img
          :src="mercadoria.foto"
          class="foto-mercadoria"
          v-if="mercadoria.foto"
        />
      </div>
      <div class="px-3">
        <div class="row align-items-center mb-3">
          <div class="col fw-bold">{{ mercadoria.nome }}</div>
          <div class="valor-mercadoria col fw-bold col-4 text-end">
            <Dinheiro :valor="mercadoria.valor" />
          </div>
        </div>
        <div class="descricao mb-5">
          {{ mercadoria.descricao }}
        </div>
      </div>
    </div>

    <Rodape />
  </div>
</template>

<script>
import BotaoVoltar from "@/components/shared/botoes/BotaoVoltar.vue";
import Dinheiro from "@/components/shared/formatadores/Dinheiro.vue";
import Rodape from "@/components/shared/Rodape.vue";

import { computed } from "@vue/reactivity";
import { SALVA_LOJA } from "@/store/mutacoes";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useApi } from "@/composables/useApi";

export default {
  name: "Mercadoria",
  props: {
    id: { type: String, required: true },
    idMercadoria: { type: Number, required: true },
    slugMercadoria: { type: String, required: true },
  },
  components: {
    BotaoVoltar,
    Dinheiro,
    Rodape,
  },
  setup(props) {
    const store = useStore();
    const loja = computed(() => store.state.loja);
    const mercadoria = computed(() =>
      loja.value.mercadorias?.find(
        (mercadoria) => mercadoria.id == props.idMercadoria
      )
    );

    useHead({
      title: computed(
        () =>
          `${loja.value?.nome} | ${mercadoria.value?.nome} | Loja MEI Grátis`
      ),
      link: [
        {
          id: "favicon",
          rel: "icon",
          href: computed(() => `${loja.value.logotipo ?? "/favicon.png"}`),
        },
        {
          rel: "stylesheet",
          href: computed(() => `/temas/${loja.value.tema}.css`),
        },
      ],
    });

    return {
      loja,
      mercadoria,
    };
  },
  async beforeRouteEnter(origem, destino, proximo) {
    const { buscaLoja } = useApi();
    proximo(async (vm) => {
      if (!vm.loja.identificador) {
        const loja = await buscaLoja(origem.params.id);
        if (loja && loja.mercadorias) {
          vm.$store.commit(SALVA_LOJA, loja);
        } else {
          vm.$router.replace({ name: "loja" });
        }
      }
    });
  },
};
</script>

<style scoped>
.cabecalho {
  background-color: var(--cor-tema);
  color: var(--cor-tema-fundo);
  padding: 1rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.cabecalho h1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.foto-mercadoria {
  width: 100%;
}
</style>